import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, message, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import HeaderSection from '../header/HeaderSection';
import './tarif.css';
import data from '../../data/tarif.json';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVilles } from '../../redux/apiCalls/villeApiCalls';

function Tarif() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const { villes } = useSelector(state => ({
        villes: state.ville.villes
      }));

    // Fetch villes data
    useEffect(() => {
        loadVilles();
    }, []);

    const loadVilles = async () => {
        setLoading(true);
        try {
            dispatch(getAllVilles())
        } catch (error) {
            message.error("Failed to load villes");
        } finally {
            setLoading(false);
        }
    };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Ref',
      dataIndex: 'ref',
      key: 'ref',
      width: '30%',
      ...getColumnSearchProps('ref'),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      ...getColumnSearchProps('nom'),
      sorter: (a, b) => a.nom.length - b.nom.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tarif',
      dataIndex: 'tarif',
      key: 'tarif',
      width: '20%',
      ...getColumnSearchProps('tarif'),
    },
  ];

  return (
    <section className='tarif'>
      <HeaderSection
        nom={`Tarif`}
        title={`Tarifs De Livraison Selon Les Villes`}
        desc={`Chaque Ville A Un Prix De Livraison, Dans La Liste Suivante Vous Trouverez Toutes Les Villes Où Nous Pouvons Livrer Et Distribuer, À condition le départ sur Marrakech `}
      />
      <div className="tarif-main">
        <Table 
          columns={columns} 
          dataSource={villes} 
          pagination={{ 
            pageSizeOptions: ['5', '10', '20', '50' , '100 '], // Options for page size
            showSizeChanger: true, // Enable the page size changer
            defaultPageSize: 10, // Default page size
          }} 
        />
      </div>
    </section>
  );
}

export default Tarif;
